/*----------------------------------------*/
/*  11. Contact CSS
/*----------------------------------------*/

/*-- Contact Form --*/
.contact-form {
    & h3 {
        font-size: 24px;
        line-height: 1;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 30px;

        // Responsive
        @media #{$large-mobile}{
            font-size: 18px;
        }
    }

    & input {
        font-family: $heading-font;
        display: block;
        width: 100%;
        border-width: 0 0 2px;
        border-style: solid;
        border-color: #dbdbdb;
        background-color: transparent;
        font-size: 14px;
        line-height: 1;
        font-weight: 500;
        letter-spacing: 1px;
        padding-bottom: 12px;
        color: $body-color;
        margin-bottom: 25px;
        &:focus {
            border-color: $body-color;
        }
    }

    & button {
        font-weight: 600;
        text-transform: uppercase;
        width: auto;
        background-color: transparent;
        padding: 5px 10px;
        color: $body-color;
        border: none;
        border-bottom: 2px solid $body-color;
        margin-bottom: 0;
        &:hover {
            color: $heading-color;
            border-color: $heading-color;
        }
    }

    & textarea {
        font-family: $heading-font;
        display: block;
        width: 100%;
        height: 80px;
        border-width: 0 0 2px;
        border-style: solid;
        border-color: #dbdbdb;
        background-color: transparent;
        font-size: 14px;
        line-height: 1;
        font-weight: 500;
        letter-spacing: 1px;
        padding-bottom: 12px;
        color: $body-color;
        margin-bottom: 25px;
        resize: none;
        &:focus {
            border-color: $body-color;
        }
    }
}

/*-- Contact Info --*/
.contact-info {
    & h3 {
        font-size: 24px;
        line-height: 1;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 30px;

        // Responsive
        @media #{$large-mobile}{
            font-size: 18px;
        }
    }

    & ul {
        & li {
            font-family: $heading-font;
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 1px;
            color: $body-color;
            margin-bottom: 12px;
            & span {
                font-size: 14px;
                font-weight: 600;
                margin-right: 5px;
                color: $heading-color;
            }
            & a {
                color: $body-color;
                &:hover {
                    color: $heading-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

/*-- Contact Availability --*/
.contact-availability {
    & h3 {
        font-size: 24px;
        line-height: 1;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 30px;

        // Responsive
        @media #{$large-mobile}{
            font-size: 18px;
        }
    }

    & p {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

/*-- Contact Map --*/
#contact-map {
    width: 100%;
    height: 270px;
    border: 3px solid $body-color;
}
